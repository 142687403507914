<script setup lang="ts">
import type { Game, GamesPayload } from "@/types";

const { t } = useT();
withDefaults(
	defineProps<{
		games: Game[];
		rows: number | null;
		skipGamePreview?: boolean;
		skipRateBadge?: boolean;
		skipNewBadge?: boolean;
		isAllGamesView?: boolean | undefined;
		isAllGamesCollapse?: boolean;
		link?: boolean;
		isGameOfWeek?: boolean;
		gameOfWeek?: Game | GamesPayload["gameOfWeek"];
	}>(),
	{
		rows: null,
		link: false,
		isAllGamesView: false,
		isGameOfWeek: false,
		gameOfWeek: undefined
	}
);

const { data: appInit } = useAppInitData();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const prepareImgUrl = useImage();
const imagePattern = `url(${prepareImgUrl(`/nuxt-img/card-games/pattern.webp`, {
	format: "webp",
	width: 300
})})`;

const { handleToggleToFavoriteClick } = useAddToFavorite();
</script>

<template>
	<div
		class="grid-cards"
		:class="{
			[`grid-cards--${rows}`]: rows,
			'with-link': link,
			'all-games-view': isAllGamesView,
			'all-games-view-collapse': isAllGamesCollapse
		}"
	>
		<MGame
			v-for="(game, index) in games"
			:key="game.id"
			:is-guest="!!appInit?.isGuest"
			:game="game"
			:is-only-for-app="!!game.isOnlyForApp"
			:is-favorite="!!game.isFavorite"
			:is-new="!!game.isNew"
			:grade="game.grade"
			:image="`${baseImageUrl}${game.logo || game.img}`"
			:image-pattern="imagePattern"
			:game-id="game?.id || 0"
			:insideTitle="false"
			:skip-game-preview="skipGamePreview"
			:skip-rate-badge="skipRateBadge"
			:skip-new-badge="skipNewBadge"
			:skeleton="'skeleton' in game"
			:style="{ order: index + 2 }"
			@toggle-favorite="handleToggleToFavoriteClick(game)"
		/>
		<OGamesBannerTop v-if="isGameOfWeek && gameOfWeek" class="game-of-week" :banner="gameOfWeek" />
		<NuxtLink v-if="rows === 3 && link" class="show-all" to="/issues/all-games">
			<AText
				variant="takoradi"
				:modifiers="['bold', 'underline', 'uppercase', 'center']"
				class="text-tlalnepantla text-coro"
			>
				{{ t("ALL GAMES") }}
			</AText>
		</NuxtLink>
	</div>
</template>

<style scoped lang="scss">
$games-in-row: 6;
$games-in-row-mob: 3;

.grid-cards {
	max-width: 100%;
	width: 1312px;
	margin: 0 auto;
	display: grid;
	grid-gap: 12px 12px;
	grid-template-columns: repeat($games-in-row, 1fr);

	@include media-breakpoint-down(lg) {
		grid-gap: 8px 8px;
	}

	@media (orientation: landscape) and (max-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (orientation: portrait) and (max-width: 580px) {
		grid-template-columns: repeat(2, 1fr);
	}

	.game-of-week {
		height: 402px;
		border-radius: 24px;
		border: 2px solid var(--chifeng);
		order: 10;
		grid-column: span 6;
		grid-row: span 2;
		position: relative;
		width: auto !important;
		margin: 24px 0;

		@media (orientation: landscape) and (max-width: 992px) {
			margin: 16px 0;
			grid-column: span 2;
			grid-row: span 2;
			order: 8;
		}

		@include media-breakpoint-down(sm) {
			margin: 16px 0;
			grid-column: span 2;
			grid-row: span 2;
			order: 8;
		}
	}

	&.all-games-view-collapse {
		& > .game-wrapper {
			&:nth-child(1),
			&:nth-child(14) {
				grid-column: span 2;
				grid-row: span 2;
				position: relative;
				width: auto !important;
			}
		}
	}

	&.all-games-view {
		& > .game-wrapper {
			&:nth-child(1),
			&:nth-child(14) {
				grid-column: span 2;
				grid-row: span 2;
				position: relative;
				width: auto !important;
			}
			&:nth-child(n + 19) {
				display: none;
			}

			@media (orientation: landscape) and (max-width: 992px) {
				&:nth-child(n + 15) {
					display: none;
				}
			}

			@include media-breakpoint-down(sm) {
				&:nth-child(n + 15) {
					display: none;
				}
			}
		}
	}

	&--1 {
		& > .game-wrapper {
			&:nth-child(n + #{$games-in-row + 1}) {
				display: none;
			}
		}

		@media (orientation: landscape) and (max-width: 992px) {
			& > .game-wrapper {
				&:nth-child(n + #{$games-in-row-mob + 1}) {
					display: none;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			& > .game-wrapper {
				&:nth-child(n + #{$games-in-row-mob + 1}) {
					display: none;
				}
			}
		}
	}

	&--2 {
		& > .game-wrapper {
			&:nth-child(n + #{$games-in-row * 2 + 1}) {
				display: none;
			}
		}

		@media (orientation: landscape) and (max-width: 992px) {
			& > .game-wrapper {
				&:nth-child(n + #{$games-in-row-mob * 2 + 1}) {
					display: none;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			& > .game-wrapper {
				&:nth-child(n + #{$games-in-row-mob * 2 + 1}) {
					display: none;
				}
			}
		}
	}

	&--3 {
		& > .game-wrapper {
			&:nth-child(n + #{$games-in-row * 3 + 1}) {
				display: none;
			}
		}

		@media (orientation: landscape) and (max-width: 992px) {
			& > .game-wrapper {
				&:nth-child(n + #{$games-in-row-mob * 3 + 1}) {
					display: none;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			& > .game-wrapper {
				&:nth-child(n + #{$games-in-row-mob * 3 + 1}) {
					display: none;
				}
			}
		}

		&.with-link {
			& > .game-wrapper {
				&:nth-child(n + #{$games-in-row * 3}) {
					display: none;
				}
			}

			@media (orientation: landscape) and (max-width: 992px) {
				& > .game-wrapper {
					&:nth-child(n + #{$games-in-row-mob * 3}) {
						display: none;
					}
				}
			}

			@include media-breakpoint-down(sm) {
				& > .game-wrapper {
					&:nth-child(n + #{$games-in-row-mob * 3}) {
						display: none;
					}
				}
			}
		}
	}

	.show-all {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		border: 1px solid var(--cannes);
		background: var(--gosaba);
		box-shadow:
			0 0 6px 0 var(--cangzhou),
			0 4px 4px 0 rgba(255, 225, 180, 0.25) inset,
			0 0 18px 0 rgba(255, 152, 220, 0.76) inset;

		&:hover {
			cursor: pointer;

			span {
				text-decoration: none;
			}
		}
	}
}
</style>
<style lang="scss">
.icon-favorite {
	background-image: url(/assets/icons/20/favorite-notify.svg);
}

.Toastify__toast-container--bottom-center {
	@include media-breakpoint-up(lg) {
		left: auto;
		right: gutter(2);
		transform: translateX(0%);
	}
}
</style>
